import React from 'react';
import "./julioCSS/Footer.css";
import Map from "../Map";

function Footer(props) {

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };
  // const { isLoaded } = useJsApiLoader ({
  //   id: "AIzaSyBwZIHTwjOEb62DXwjnuKziwBwWvK7AM3U",
  //   googleMapsApiKey: "AIzaSyBwZIHTwjOEb62DXwjnuKziwBwWvK7AM3U",
  // });

  return (
    
    

    <footer className={props.className}>
      <div className="footer-container">
        <div className="footer-left">
          <h3>Current Location</h3>
          <p>2486 Maple St, </p>
          <p>San Bernardino, CA 92410</p>
          <p>Phone: (420) 420-6969</p>
          <p>Email: Email to be added</p>
        </div>
        <div className="footer-middle">
          <h3>Navigation</h3>
          <ul>
          <li><a href= "/about" onClick={handleLinkClick}> About </a></li> 
          <li><a href= "/services" onClick={handleLinkClick}> Services </a></li>
          <li><a href= "/portfolio" onClick={handleLinkClick}> Portfolio </a></li>
          <li><a href= "/contact" onClick={handleLinkClick}> Contact </a></li>
          <li><a href= "/payment" onClick={handleLinkClick}> Payment </a></li>
          </ul>
        </div>
        <div className="footer-right">
          <h3>Where Im At</h3>
          <Map/>
        </div>
      </div>
      <div className="footer-bottom">
        <p>Copyright © 2023 JayyTatts.
          All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
