// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {getStorage} from "firebase/storage";
import {getFirestore} from "@firebase/firestore" // Firebase Datrabase added by Eric
import { getAuth } from "firebase/auth";


// Referenced this video for button and file access:
// https://www.youtube.com/watch?v=YOAeBSCkArA

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCMD27W-RBBn1Her46O1R24F8FgU23la44",
    authDomain: "jayytattsweb-410323.firebaseapp.com",
    projectId: "jayytattsweb-410323",
    storageBucket: "jayytattsweb-410323.appspot.com",
    messagingSenderId: "444485019893",
    appId: "1:444485019893:web:4a2d572616bb0532707dbc",
    measurementId: "G-S9766SXZKC"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app); // Firebase Database added by Eric
export const storage = getStorage(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);